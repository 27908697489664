<template>
  <div >
    <header  style="position: relative;">
      <div style=" margin: 0 auto;">
          <img style="width: 100%;height: 100%;object-fit: contain;" src="../assets/images/bosscase/Group 2167.png" />
      </div>
          <div class="boss-content" style="z-index: 9;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;">
            <div style="position: relative;left: 58px">
              <div class="boss-content">
                <h1 style="font-size: 50px;"><b>成功案例</b></h1>
                <p style="font-size: 20px;">标杆企业高质量数字化转型</p>
                <br>
                <el-button class="image-button" @click="router()">方案咨询<img src='../assets/images/home_slices/arrowhead.png' style="margin-left: 30px;height: 15px;margin-bottom: 3px"></el-button>
              </div>
            </div>
          </div>
    </header>
    <main style="background: rgba(255, 255, 255, 255)">
      <div class="boss-content" >
        <el-menu  :default-active=active mode="horizontal" @select="handleSelect" style="margin-bottom: 30px;">
          <el-menu-item
            v-for="(items, key) in titles"
            :key="key"
            :index="items.id"
          >
            {{ items.cnname }}
          </el-menu-item>
        </el-menu>
        <el-row :gutter="40">
            <el-col :xs="8" :md="8" style="margin-bottom: 40px;"
            v-for="(item, index) in datas"
              :key="index">
              <div class="grid-content bg-purple"  @click="docs(item)" style="cursor: pointer;box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);position: relative;">
                <div style="position: absolute;top: 10px;right: 8px; ">
                  <el-tag  effect="plain" style="opacity:0.5; line-height: 20px;height: 20px;padding-left: 3px;padding-right: 3px;
                  margin-left: 10px;">{{item.type}}</el-tag>
                </div>
                <div style="height: 180px;width:100%;">
                    <img
                    style="width: 100%;height: 100%;object-fit: contain;"
                      :src="item.imagePath ? $imgUrl + item.imagePath : ''"
                    />
                </div>
                <div style="height: 100px;width: 100%;">
                    <img
                    style="width: 100%;height: 100%;object-fit: contain;"
                      :src="item.logoPath ? $imgUrl + item.logoPath : ''"
                    />
                </div>
                <div style="height: 60px;width: 100%;margin-left: 10px;margin-right: 10px;margin-top: 10px;">
                  <h4>{{ item.ename }}</h4>
                </div>
                <div style="height: 120px;margin-left: 10px;margin-right: 10px;">
                  <p>{{ item.description }}</p>
                </div>
                <div v-if="item.tag.length>=0" style="height: 80px;margin-left: 10px;margin-right: 10px;">
                  <el-tag  v-for="tag in item.tag" :key="tag" :type="tag" style="line-height: 20px;height: 20px;
                  padding-left: 3px;padding-right: 3px;margin-right: 10px;">{{
                    tag
                  }}</el-tag>
                </div>
              </div>
            </el-col>
          </el-row>

        <div class="block">
          <el-pagination
            layout="prev, pager, next,total, jumper"
            :current-page="this.queryInfo.pagenum"
            :page-size="this.queryInfo.pagesize"
            :total="this.queryInfo.total"
            @current-change="handleCurrentChange"
            style="display: flex; justify-content: center; border-bottom: none;margin-bottom: 50px;"
          >
          </el-pagination>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  components: {},
  data() {
    return {
      queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 6,
        total: 0,
      },
      datas: [], //文章
      titles: [], //导航
      active:'dde6f4137f434f11b88217aae9a3eef8',
      w: "360px",
    };
  },
  computed: {},
  watch: {},
  methods: {
    router() {
      this.$router.push("/apply/");
    },

    docs(val) {
      this.$router.push("/quarterlyFile/programme/" + val.id);
    },

    handleCurrentChange(val) {
      this.queryInfo.pagenum = val;
      this.getData();
    },
    handleSelect(key, keyPath) {
      this.queryInfo.query = key;
      this.queryInfo.pagenum=1;
      this.getData();
    },
    async getTitle() {
      let _this = this;
      await _this.$http.get(`/getAnliTitle`).then((e) => {
        if (e.data.code == 200) {
          this.titles = e.data.data;
        }
      });
    },
    async getData() {
      let _this = this;
      this.getTitle();
      await _this.$http
        .get(`/AllProduct`, { params: _this.queryInfo })
        .then((e) => {
          console.log(e);
          if (e.data.code == 200) {
            this.queryInfo.total = e.data.data.total;
            this.datas = [];
            for (let index = 0; index < e.data.data.product.length; index++) {
              const element = e.data.data.product[index];
              let tag = element.product.split("、");
              element.tag = tag;
              this.datas.push(element);
            }
          }
        });
      // 动画重启
      this.$nextTick(() => {
        // 在dom渲染完后,再执行动画
        var wow = new WOW({
          live: false,
        });
        wow.init();
      });
    },
    getW() {
      let screenWidth = document.body.clientWidth;
      if (screenWidth < 768) {
        this.w = "200px";
      } else {
        this.w = "360px";
      }
    },
  },
  created() {},
  mounted() {
    window.onresize = () => {
      return (() => {
        this.getW();
      })();
    };
    this.getData();
    this.getW();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
.image-button {
  background: linear-gradient(to right, #2840d4, #399ff7);
  color: white;
  width: 180px;
  height: 50px;
  outline: none;
}
.image-button:focus{
  box-shadow: none;
  outline-color: transparent;
  outline: none;
}

/* 设置导航栏文字颜色为黑色 */
.el-menu-item {
    color: black;
  }
  
  /* 设置鼠标悬停或被选中时文字加粗 */
.el-menu-item:hover,.el-menu-item.is-active{
    font-weight: bold;
  }
  .el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: 2px solid #0139d0;
    color: #303133;
}

.el-aside {
    color: #333;
}
::v-deep.el-menu {
  border-bottom: none;
}
// .el-tag + .el-tag {
  
// }
.header {
  background: url("../assets/images/bosscase/Group 2167.png");
  background-size: 100%;
  height: 25rem;
  position: relative;
  z-index: 1;
}
//@import url(); 引入公共css类
.textcenter {
  text-align: center;
  margin-bottom: 50px;
  font-weight: 800;
}
.smoothie {
  transition: all 0.4s ease-in-out;
}
.smo {
  display: block;
  //   height: 360px;
  margin: 20px 0;
  &:hover {
    .hover-effect {
      .img-responsive {
        transform: scale(1);
      }
      .hover-overlay {
        top: 100px;
        opacity: 0;
      }
      .hover-caption {
        opacity: 1;
        transform: scale(1);
        overflow: hidden;
      }
    }
  }

  .hover-effect {
    position: relative;
    overflow: hidden;
    height: inherit;
    .img-responsive {
      width: 100%;
      transform: scale(1.1);
      backface-visibility: hidden;
      display: block;
      max-width: 100%;
      //   height: auto;
      height: 100%;
      vertical-align: middle;
    }

    .hover-caption,
    .hover-overlay {
      position: absolute;
      width: calc(100% - 30px);
      height: calc(100% - 30px);
      top: 15px;
      left: 15px;
      opacity: 0;
      padding: 0 20px;
    }
    .hover-overlay {
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.4) 100%
      );
      opacity: 1;
      color: #fff;
    }
    .vertical-align-bottom {
      bottom: 10px;
      position: absolute;
    }
    .vertical-align-top {
      //   position: absolute;
      //   top: 15px;
      //   width: 100%;
      //   box-sizing: border-box;
      margin-top: 20px;
    }

    h4 {
      //   letter-spacing: 5px;
      text-align: left;
      font-size: 20px;
      font-weight: 700;
      width: calc(100% - 40px);
    }
    .item-category-span {
      clear: left;
      background-color: #f64747;
      color: #fff;
      padding: 1px 7px 0;
      display: block;
      float: left;
      margin: 0px 0 20px;
      letter-spacing: 2px;
      font-size: 11px;
      font-weight: 300;
      text-transform: uppercase;
      line-height: 19px;
    }
    .hover-caption,
    .hover-caption .vertical-align-top {
      color: #fff;
      font-size: 14px;
      letter-spacing: 1px;
    }
    .hover-caption {
      transform: scale(0.9);
      background-color: rgba(0, 0, 0, 0.6);
      color: #222;
    }
    .btn-primary {
      background-color: #f64747;
      border-color: #f64747;
    }
  }
}
.header h5 {
  max-width: 600px;
}
</style>